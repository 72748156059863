<template>
  <div class="ratio-grade">
    <el-form ref="form" :model="sourceData" :rules="rules">
      <el-table
        :data="sourceData.tableData"
        border
        style="width: 100%; margin-bottom: 18px"
      >
        <el-table-column prop="name" label="等级" align="center">
        </el-table-column>
        <el-table-column label="设定比例" align="center">
          <template slot-scope="{ row, $index }">
            <el-form-item
              class="table-form-item"
              :prop="'tableData.' + $index + '.apProportion'"
              :rules="rules.apProportion"
            >
              <el-input
                v-model="row.apProportion"
                class="table-input"
                style="width: 96px"
              ></el-input>
              %
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="realTotal" label="人数" align="center">
        </el-table-column>
        <el-table-column prop="realProportion" label="实际比例" align="center">
          <template slot-scope="{ row }"> {{ row.realProportion }}% </template>
        </el-table-column>
      </el-table>
    </el-form>
    <div class="operate" style="padding: 0">
      <el-checkbox
        v-model="apCalculateWithScore"
        :true-label="1"
        :false-label="0"
        >同步生成“分数分级”</el-checkbox
      >
      <el-dropdown @command="handleCommand">
        <el-button :loading="loading">
          {{ text }}<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="1">应用</el-dropdown-item>
          <el-dropdown-item command="2">应用更多科目</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-dialog
      title="应用至更多科目"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="handleClose"
    >
      <div class="title">对以下科目应用相同数值进行赋分</div>
      <el-checkbox-group v-model="checkList">
        <el-checkbox
          v-for="(item, index) in subjectList"
          :key="index"
          :disabled="subjectId == item.id"
          :label="item.id"
          >{{ item.name }}</el-checkbox
        >
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="loading" @click="submit"
          >应 用</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { setstatsubjectapproportion } from "@/core/api/academic/newExam";
// import { Message } from "element-ui";
import { getstatmethodsetting } from "@/core/api/academic/statistics";
export default {
  props: {
    subjectList: {
      type: Array,
      default: () => [],
    },
    sourceData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const validProportion = (rule, value, callback) => {
      const reg = /^\d*\.?\d+$/;
      let data = Number(value);

      if (!reg.test(data)) {
        callback(new Error(" "));
        // Message({
        //   type: "info",
        //   message: "比例只能为正数或小数",
        //   showClose: true,
        // });
      } else {
        let total = this.sourceData.tableData.reduce((total, prev) => {
          total = total + Number(prev.apProportion);
          return total;
        }, 0);
        if (total !== 100) {
          callback(new Error(" "));
          // Message({
          //   type: "info",
          //   message: "设定比例总和必须为100",
          //   showClose: true,
          // });
        } else {
          callback();
          this.disabled = false;
          this.$refs.form.clearValidate();
        }
      }
    };
    return {
      rules: {
        apProportion: [{ validator: validProportion, trigger: "blur" }],
      },
      tableData: [],
      disabled: false,
      synchronous: false,
      dialogVisible: false,
      checkList: [],
      loading: false,
      text: "应用",
      apCalculateWithScore: 1,
    };
  },
  computed: {
    ...mapState({
      subjectId: (state) => state.report.subjectId,
    }),
  },
  mounted() {
    this.getStatSetting();
  },
  methods: {
    async getStatSetting() {
      const res = await getstatmethodsetting({ id: this.$route.query.id });
      this.apCalculateWithScore = res.data.data.stat.apCalculateWithScore;
    },
    initForm() {
      this.$refs.form.clearValidate();
    },
    ratioChange() {
      this.ratioDisabled = !this.ratioGradeData.every(
        (item) => item.ratio || item.ratio === 0
      );
    },
    handleClose() {
      if (!this.laoding) {
        this.dialogVisible = false;
      }
    },
    async submit() {
      this.loading = true;
      try {
        let values = this.sourceData.tableData.map((item) =>
          Number(item.apProportion)
        );
        const res = await setstatsubjectapproportion({
          id: this.$route.query.id,
          subjectIdMore: this.checkList.join(","),
          subjectId: this.subjectId,
          apCalculateWithScore: this.apCalculateWithScore,
          values: values,
        });
        this.$emit("setNewData", res.data.data);
        this.loading = false;
        this.dialogVisible = false;
        this.$message({
          type: "success",
          showClose: true,
          message: "提交成功",
        });
      } catch {
        this.loading = false;
      }
    },
    async handleCommand(e) {
      if (this.subjectId) {
        this.$refs.form.validate(async (valid) => {
          if (valid) {
            this.$refs.form.clearValidate();
            if (e === "1") {
              let values = this.sourceData.tableData.map((item) =>
                Number(item.apProportion)
              );
              this.loading = true;
              this.text = "正在应用比率分级";
              this.$emit("loading");
              await setstatsubjectapproportion({
                id: this.$route.query.id,
                subjectId: this.subjectId,
                apCalculateWithScore: this.apCalculateWithScore,
                values: values,
              });
              this.$parent.containerLoading = false;
              this.loading = false;
              this.text = "应用";
              this.$emit("submit");
            } else if (e === "2") {
              this.dialogVisible = true;
              this.$nextTick(() => {
                this.checkList = [this.subjectId];
              });
            }
          } else {
            const reg = /^\d*\.?\d+$/;
            let result = this.sourceData.tableData.some(
              (item) => !reg.test(item.apProportion)
            );
            if (result) {
              this.$message({
                type: "error",
                message: "比例只能为正数或小数",
                showClose: true,
              });
            } else {
              let total = this.sourceData.tableData.reduce((total, prev) => {
                total = total + Number(prev.apProportion);
                return total;
              }, 0);
              if (total !== 100) {
                this.$message({
                  type: "error",
                  message: "设定比例总和必须为100",
                  showClose: true,
                });
              }
            }
          }
        });
      } else {
        this.$message({
          showClose: true,
          message: "当前统计没有可赋分的选考科目",
          type: "warning",
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.operate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  padding: 0 18px;
  ::v-deep .el-radio {
    margin-bottom: 0;
  }
}
.title {
  color: #0a1119;
  font-size: 14px;
  margin-bottom: 18px;
}
.table-form-item {
  margin-bottom: 0;
}
</style>
