<template>
  <div class="contrast">
    <div class="contrast-title">
      <el-input v-if="showInput" v-model="title"></el-input>
      <span v-else>{{ title }}</span>
    </div>
    <div class="contrast-table">
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        :cell-style="{
          height: '54px',
        }"
        class="contrast-table-con"
      >
        <el-table-column prop="apProportion" label="设定比例" align="center">
          <template slot-scope="{ row }">
            {{ row.apProportion || 0 }} %
          </template>
        </el-table-column>
        <el-table-column prop="realTotal" label="人数" align="center">
        </el-table-column>
        <el-table-column prop="realProportion" label="实际比例" align="center">
          <template slot-scope="{ row }"> {{ row.realProportion }} % </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="float-btn" @click="setContrast">记录为对比值</div>
  </div>
</template>
<script>
export default {
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showInput: false,
      title: "比例划线对比参考",
    };
  },
  methods: {
    setContrast() {
      this.$emit("updataContrast");
    },
  },
};
</script>
<style lang="scss" scoped>
.contrast {
  width: 350px;
  border-radius: 4px;
  box-sizing: border-box;
  height: 443px;
  padding: 18px;
  border-top: 1px solid #ebecee;
  position: relative;
  &-title {
    height: 32px;
    border-radius: 4px;
    border: 1px solid #ebecee;
    text-align: center;
    line-height: 32px;
    cursor: pointer;
    margin-bottom: 18px;
    span {
      display: block;
      width: 100%;
      height: 100%;
    }
    ::v-deep .el-input__inner {
      text-align: center;
    }
  }
  .contrast-table {
    width: 100%;
    ::v-deep .el-table .el-table__header th {
      background-color: #fff;
    }
  }
  .float-btn {
    position: absolute;
    top: 50%;
    left: -50px;
    width: 32px;
    height: 142px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #dadbe0;
    text-align: center;
    cursor: pointer;
    transform: translateY(-50%);
    writing-mode: vertical-rl;
    line-height: 32px;
    letter-spacing: 1px;
    user-select: none;
  }
}
</style>
