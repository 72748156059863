<template>
  <div class="subjects">
    <template v-if="subjectList.length">
      <div
        v-for="(item, index) in subjectList"
        :key="index"
        :class="['subject', subjectId === item.id ? 'active' : '']"
        @click="handleClick(item)"
      >
        {{ item.name }}
      </div>
    </template>
    <template v-else>
      <div class="tip">无可赋分的选考科目</div>
    </template>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: {
    subjectList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState({
      subjectId: (state) => state.report.subjectId,
    }),
  },

  methods: {
    handleClick({ id }) {
      this.$store.commit("report/SET_SUBJECT_ID", id);
    },
  },
};
</script>
<style lang="scss" scoped>
.subjects {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: #0a1119;
  font-size: 14px;
  .subject {
    height: 32px;
    border-radius: 4px;
    border: 1px solid #d5d6db;
    line-height: 32px;
    cursor: pointer;
    box-sizing: border-box;
    padding: 0 18px;
    margin-right: 18px;
    margin-bottom: 18px;
  }
  .active {
    border-color: #2474ed;
    color: #2474ed;
  }
  .tip {
    margin-bottom: 18px;
    color: #909399;
    font-size: 12px;
  }
}
</style>
