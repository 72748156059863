export const levelList = [
  {
    label: "A",
    value: "100～86分",
  },
  {
    label: "B",
    value: "85～71分",
  },
  {
    label: "C",
    value: "70～56分",
  },
  {
    label: "D",
    value: "55～41分",
  },
  {
    label: "E",
    value: "40～30分",
  },
];
