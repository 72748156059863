<template>
  <div class="originCreate">
    <div class="img">
      <img src="@/assets/imgs/academic/group1.png" alt="" />
      <img src="@/assets/imgs/academic/arrow2.png" class="arrow" alt="" />
      <img src="@/assets/imgs/academic/group2.png" alt="" />
    </div>
    <div class="text">
      <div>使用原始分数作为赋分结果</div>
      <div>若要修改赋分，可通过成绩管理进行操作</div>
    </div>
    <el-button type="primary" :loading="loading" @click="create"
      >生成赋分</el-button
    >
  </div>
</template>
<script>
import { calculateapscorebyscoreori } from "@/core/api/academic/statistics";
import { mapState } from "vuex";
export default {
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState({
      subjectId: (state) => state.report.subjectId,
    }),
  },
  methods: {
    async create() {
      this.loading = true;
      try {
        await calculateapscorebyscoreori({
          id: this.$route.query.id,
          subjectId: this.subjectId,
        });
        this.loading = false;
        this.$emit("submit");
        let subject = this.$parent.subjectList.find(
          (item) => item.id === this.subjectId
        );
        this.$message({
          type: "success",
          message: "科目“" + subject.name + "”比例分级已计算完成。",
          showClose: true,
        });
      } catch {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.originCreate {
  width: 100%;
  height: 445px;
  margin-bottom: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .img {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 32px;
    user-select: none;
    .arrow {
      margin: 0 36px;
    }
  }
  .text {
    color: #3e4551;
    font-size: 14px;
    text-align: center;
    margin-bottom: 18px;
  }
}
</style>
