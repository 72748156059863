<template>
  <div v-loading="loading" class="steps">
    <el-steps direction="vertical" :active="active">
      <el-step title="选择科目">
        <template slot="description">
          <div class="radio">
            <el-radio-group v-model="subjectId" @change="radioChange">
              <el-radio
                v-for="(item, index) in subjectList"
                :key="index"
                :label="item.id"
                >{{ item.name }}</el-radio
              >
            </el-radio-group>
          </div>
        </template>
      </el-step>
      <el-step title="下载模板">
        <template slot="title">
          下载模板
          <el-popover
            placement="bottom"
            popper-class="define-popper"
            trigger="hover"
          >
            <div class="tooltip-title">提示</div>
            <img src="@/assets/imgs/academic/table.png" alt="" />
            <span slot="reference" class="tooltip-icon">
              <i class="el-icon-warning"></i>
            </span>
          </el-popover>
        </template>
        <template slot="description">
          <div v-if="!subjectId" class="desc">
            <div class="pending"></div>
            待创建
          </div>
          <div v-else class="desc success">
            <i class="el-icon-success"></i> 已生成：{{ fileName }}
          </div>
          <el-button
            type="primary"
            style="margin-bottom: 38px"
            :loading="downloadbtnLoading"
            @click="downloadTemplate"
            >下载模板</el-button
          >
        </template>
      </el-step>
      <el-step title="上传表格">
        <template slot="description">
          <div v-if="isOver" class="desc fail">
            <i class="el-icon-error"></i
            >上传失败：文件大小过大，请选择小于5MB的文件。
          </div>
          <div v-else-if="notExcel" class="desc fail">
            <i class="el-icon-error"></i
            >上传失败：文件格式错误，请选择后缀名为.xls或.xlsx格式的文件。
          </div>
          <div v-else-if="!fileObj" class="desc">
            <div class="pending"></div>
            <span>待上传</span>
          </div>

          <div v-else-if="fileObj" class="desc success">
            <i class="el-icon-success"></i>已上传：{{ fileObj.name }}
          </div>
          <el-upload
            ref="upload"
            class="upload-demo"
            :action="uploadAvatarURL"
            :headers="uploadHeader"
            :file-list="fileList"
            :on-success="uploadSuccess"
            :on-error="uploadError"
            :on-progress="uploadProgress"
            :on-change="uploadChange"
            style="margin-bottom: 18px"
            name="file"
            accept=".xls,.xlsx"
            :show-file-list="false"
            :auto-upload="false"
          >
            <el-button slot="trigger" type="primary">
              {{ fileObj ? "重新选择" : "选取文件" }}
            </el-button>
          </el-upload>
        </template>
      </el-step>
      <el-step title="录入结果">
        <template slot="description">
          <div v-if="type === 0" class="desc">
            <div class="pending"></div>
            待录入
          </div>
          <div v-if="type === 1" class="desc success">
            <i class="el-icon-success"></i>录入中：{{ percent }} %
          </div>
          <div v-if="type === 2" class="desc fail">
            <i class="el-icon-error"></i> {{ errorText }}
          </div>
          <div v-if="type == 3" class="desc success">
            <i class="el-icon-success"></i>已导入
          </div>
          <el-button
            v-if="active == 3 && type !== 3"
            type="primary"
            :disabled="disabledSubmit"
            @click="uploadSubmit"
            >提交</el-button
          >
          <el-button v-if="type == 3" type="primary" @click="achivement"
            >完成</el-button
          >
        </template>
      </el-step>
    </el-steps>
  </div>
</template>
<script>
import { getStore } from "@/core/util/store";
import { mapState } from "vuex";
import { downloadstatstudentsubjectapscoretemplate } from "@/core/api/academic/statistics";
export default {
  data() {
    return {
      active: 1,
      fileName: "",
      fileList: [],
      fileObj: null,
      percent: null,
      errorText: "",
      uploadAvatarURL:
        "/report/stat/importstatstudentsubjectapscore" +
        "?id=" +
        this.$route.query.id,
      uploadHeader: {
        Authorization: "Bearer " + getStore({ name: "access_token" }),
        "SCHOOL-ID": getStore({ name: "school_id" }),
      },
      type: 0, // 0 待上传 1 上传中 2 上传失败 3 已上传
      errorMessage: "",
      isOver: false, // 是否超出
      notExcel: false,
      disabledSubmit: true,
      subjectId: null,
      downloadbtnLoading: false,
    };
  },
  computed: {
    ...mapState({
      subjectList: (state) => state.report.subjectList,
      parentSubjectId: (state) => state.report.subjectId,
    }),
  },
  watch: {
    fileObj: {
      deep: true,
      handler(val) {
        if (val) {
          this.active = 3;
        }
      },
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.subjectId = this.parentSubjectId;
      this.radioChange();
    });
  },
  methods: {
    radioChange() {
      this.active = 2;
      let subject = this.subjectList.find((item) => item.id == this.subjectId);
      this.fileName =
        subject.name + "导入赋分模板" + this.getTime() + "_" + this.randomStr();
    },
    achivement() {
      this.$emit("achivement");
      this.$refs.upload.clearFiles();
      this.fileObj = null;
      this.type = 0;
      this.percent = null;
      this.active = 1;
    },
    uploadProgress(event) {
      this.type = 1;
      this.percent = event.percent;
    },
    setStorage() {
      // localStorage.setItem("epxort-ap", this.fileObj.name);
      let storage = localStorage.getItem("export-ap");
      let obj = {};
      if (storage) {
        obj = JSON.parse(storage);
      }
      obj[`${this.$route.query.id}-${this.subjectId}`] = this.fileObj.name;
      localStorage.setItem("export-ap", JSON.stringify(obj));
    },
    uploadSuccess(res) {
      if (res.code != 0) {
        this.type = 2;
        if (res.msg.indexOf(".xls") != -1) {
          this.errorText = "导入失败：所填数据不符合规则，请核查所上传的表格。";
          let name = res.msg.substring(
            res.msg.lastIndexOf("/") + 1,
            res.msg.lastIndexOf(".")
          );
          const { href } = this.$router.resolve({
            path: "/downFile",
            query: {
              url: res.msg,
              name: name,
              type: 1,
            },
          });
          let timer = setTimeout(() => {
            window.open(href, "_blank");
            clearTimeout(timer);
          }, 0);
        } else {
          this.errorText = res.msg;
        }
        this.disabledSubmit = true;
      } else {
        this.$message({
          type: "success",
          message: "赋分结果导入成功",
          showClose: true,
        });
        this.type = 3;
        this.setStorage();
      }
    },
    uploadError() {
      this.type = 2;
      this.errorText = "导入失败：所填数据不符合规则，请核查所上传的表格。";
      this.disabledSubmit = true;
    },
    uploadChange(file) {
      if (!this.fileObj || this.fileObj.uid != file.uid) {
        this.isOver = file.size / 1024 / 1024 > 5;
        var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
        this.notExcel = testmsg !== "xls" && testmsg !== "xlsx";
        if (this.isOver || this.notExcel) {
          this.disabledSubmit = true;
          this.fileObj = null;
          this.$refs.upload.clearFiles();
        } else {
          this.disabledSubmit = false;
          this.fileObj = { ...file };
          this.fileList = [file];
          this.type = 0;
          this.notExcel = false;
          this.isOver = false;
        }
      }
    },
    async downloadTemplate() {
      if (this.subjectId) {
        this.downloadbtnLoading = true;
        try {
          const { id } = this.$route.query;
          const res = await downloadstatstudentsubjectapscoretemplate({
            id: id,
            subjectId: this.subjectId,
          });
          const { href } = this.$router.resolve({
            path: "/downFile",
            query: {
              url: res.data.data.url,
              name: res.data.data.name,
              type: 1,
            },
          });
          let timer = setTimeout(() => {
            window.open(href, "_blank");
            clearTimeout(timer);
          }, 0);
          this.uploadAvatarURL =
            this.uploadAvatarURL + "&subjectId=" + this.subjectId;
          this.downloadbtnLoading = false;
          this.active = 3;
        } catch {
          this.downloadbtnLoading = false;
        }
      } else {
        this.$message({
          type: "info",
          message: "请选择科目",
          showClose: true,
        });
      }
    },
    uploadSubmit() {
      this.uploadAvatarURL = `/report/stat/importstatstudentsubjectapscore?id=${this.$route.query.id}&subjectId=${this.subjectId}`;
      this.$nextTick(() => {
        this.$refs.upload.submit();
      });
    },
    RandomIndex(min, max, i) {
      const _charStr =
        "abacdefghjklmnopqrstuvwxyzABCDEFGHJKLMNOPQRSTUVWXYZ0123456789";
      let index = Math.floor(Math.random() * (max - min + 1) + min),
        numStart = _charStr.length - 10;
      //如果字符串第一位是数字，则递归重新获取
      if (i == 0 && index >= numStart) {
        index = this.RandomIndex(min, max, i);
      }
      //返回最终索引值
      return index;
    },
    randomStr() {
      const _charStr =
        "abacdefghjklmnopqrstuvwxyzABCDEFGHJKLMNOPQRSTUVWXYZ0123456789";
      let min = 0,
        max = _charStr.length - 1,
        _str = "";
      let len = 4;
      for (var i = 0, index; i < len; i++) {
        index = this.RandomIndex(min, max, i);
        _str += _charStr[index];
      }
      return _str;
    },
    getTime() {
      var date = new Date(); // 获取时间
      var year = date.getFullYear(); // 获取年
      var month = date.getMonth() + 1; // 获取月
      var day = date.getDate(); // 获取日
      let monthStr = month.toString().length > 1 ? month : "0" + month;
      let dayStr = day.toString().length > 1 ? day : "0" + day;
      return year + monthStr + dayStr;
    },
  },
};
</script>
<style lang="scss" scoped>
.radio {
  box-sizing: border-box;
  padding: 18px 0;
}
.tooltip-icon {
  font-size: 18px;
  color: #2474ed;
}
.tooltip-title {
  font-weight: 400;
  font-size: 12px;
  color: #484849;
  margin-bottom: 10px;
}
::v-deep.el-popper {
  background: #fff5db !important;
}
::v-deep .el-step__icon {
  width: 24px;
  height: 24px;
  border: 1px solid;
  .el-step__icon-inner {
    font-size: 14px;
    font-weight: 400;
  }
}
::v-deep .el-step__head.is-process {
  border-color: #ebecee !important;
  color: #999b9f !important;
}
::v-deep .el-step__head.is-finish {
  border-color: #2474ed !important;
  color: #2474ed !important;
}
::v-deep .el-step__title.is-finish {
  color: #0a1119;
  font-weight: 600;
  font-size: 14px;
}
::v-deep .el-step__title.is-process {
  color: #0a1119;
  font-weight: 600;
  font-size: 14px;
}
::v-deep .el-step__title.is-wait {
  color: #0a1119;
  font-weight: 600;
  font-size: 14px;
}
::v-deep .el-step__line {
  width: 1px !important;
  background-color: #ebecee !important;
}
.desc {
  margin: 18px 0;
  color: #0a1119;
  font-size: 14px;
  display: flex;
  align-items: center;
  font-weight: 400;
  vertical-align: center;
}
.success {
  i {
    font-size: 16px;
    color: #68c23a;
    margin-right: 8px;
  }
}
.pending {
  display: inline-block;
  width: 14px;
  height: 14px;
  background-color: #d5d6db;
  margin-right: 8px;
  border-radius: 50%;
}
.fail {
  i {
    font-size: 16px;
    margin-right: 8px;
    color: #f56c6b;
  }
}
</style>
<style>
.define-popper {
  background: #fff5db;
}
</style>
