<template>
  <div class="history-ratio">
    <div class="row">
      <div class="label">年级：</div>
      <el-select
        v-model="year"
        style="flex: 1"
        placeholder="请选择"
        @change="yearChange"
      >
        <el-option
          v-for="item in yearList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div class="row">
      <div class="label">考试场次：</div>
      <el-select
        v-model="statId"
        style="flex: 1"
        placeholder="请选择"
        @change="statChange"
      >
        <el-option
          v-for="item in statList"
          :key="item.name"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </div>
    <div class="row">
      <div class="label">考试科目：</div>
      <el-select
        v-model="subjectId"
        style="flex: 1"
        placeholder="请选择"
        @change="subjectChange"
      >
        <el-option
          v-for="item in subjectList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </div>
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column prop="name" label="等级" align="center">
      </el-table-column>
      <el-table-column prop="proportion" label="比例" align="center">
      </el-table-column>
    </el-table>
    <div class="tips">*仅显示本次新高考规则一致的考试场次。</div>
  </div>
</template>
<script>
import { getreusestatsubjectaplist } from "@/core/api/academic/newExam";
export default {
  data() {
    return {
      value: "",
      options: [],
      year: "",
      yearList: [],
      statList: [],
      subjectId: "",
      subjectList: [],
      tableData: [],
      statId: "",
      values: [],
      levels: ["A", "B", "C", "D", "E"],
    };
  },
  mounted() {
    const nowYear = new Date().getFullYear();
    const arr = new Array(nowYear - 2014).fill("");
    let years = arr.map((item, index) => {
      return { label: index + 2015, value: index + 2015 };
    });
    const yearList = years.reverse();
    this.yearList = [...yearList];
    this.year = this.$route.query.year;
    this.getHistoryList();
  },
  methods: {
    yearChange() {
      this.getHistoryList();
    },
    async getHistoryList() {
      const res = await getreusestatsubjectaplist({
        id: this.$route.query.id,
        year: this.year,
      });
      this.statList = res.data.data;
      if (this.statList.length) {
        this.statId = this.statList[0].id;
        let data = this.statList.find((item) => item.id === this.statId);
        this.subjectList = data.subjectList;
        if (this.subjectList.length) {
          this.subjectId = this.subjectList[0].id;
          this.subjectChange();
        }
      } else {
        this.statId = null;
        this.subjectList = [];
        this.subjectId = null;
      }
    },
    statChange() {
      let data = this.statList.find((item) => item.id === this.statId);
      this.subjectList = data.subjectList;
    },
    subjectChange() {
      let data = this.subjectList.find((item) => item.id === this.subjectId);
      this.values = data.values;
      this.tableData = data.values.map((item, index) => ({
        name: this.levels[index],
        proportion: item,
      }));
    },
  },
};
</script>
<style lang="scss" scoped>
.history-ratio {
  .row {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    .label {
      color: #0a1119;
      font-size: 14px;
      margin-right: 10px;
      width: 75px;
      white-space: nowrap;
      text-align: justify;
      text-align-last: justify;
    }
  }
  .tips {
    font-size: 14px;
    color: #3e4551;
    margin-top: 10px;
  }
}
</style>
