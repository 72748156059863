<template>
  <div class="ap">
    <!-- 赋分设置 -->
    <div class="ap-type">
      <div :class="['apSource-group']">
        <div
          v-for="(item, index) in apSourceList"
          :key="index"
          :class="['apSource', containerLoading ? 'ban' : '']"
          @click="apSourceChange(item.value)"
        >
          <div
            :class="['check', apInfo.apSource === item.value ? 'checked' : '']"
          ></div>
          {{ item.label }}
        </div>
      </div>
    </div>
    <div class="content">
      <div class="left">
        <div class="table-contain">
          <line-chart
            v-if="
              apInfo.apSource === 0 ||
              ((apInfo.apSource === 1 || apInfo.apSource === 2) && !isReset)
            "
            :key="chartKey"
            :subject-list="subjectList"
            :series="chartSeries"
          />
          <div v-if="apInfo.apSource === 1 && !isReset" class="reUpload">
            已导入表格：{{ apFileName }}
            <el-button type="primary" @click="reUpload">重新导入</el-button>
          </div>
          <upload-file
            v-if="apInfo.apSource == 1 && isReset"
            @submit="upDate"
          />
          <origin-create
            v-if="apInfo.apSource == 2 && isReset"
            @submit="upDate"
          />
          <el-collapse v-if="apInfo.apSource === 0" v-model="activeNames">
            <div :class="{ 'table-active': apInfo.apCalculateType === 0 }">
              <el-collapse-item name="1">
                <template slot="title">
                  <div class="operate" @click.stop="">
                    <el-radio
                      v-model="apInfo.apCalculateType"
                      :label="0"
                      @change="radioChange"
                      >使用比例分级</el-radio
                    >
                    <div class="btns">
                      <el-button
                        icon="el-icon-refresh-right"
                        :disabled="containerLoading"
                        @click.stop="resetDefault"
                        >恢复默认比例</el-button
                      >
                      <el-button
                        icon="el-icon-time"
                        :disabled="containerLoading"
                        @click.stop="chooseHistory"
                        >选用历史比例</el-button
                      >
                    </div>
                  </div>
                </template>
                <ratio-table
                  ref="ratioTable"
                  :source-data="{ tableData: tableData }"
                  :subject-list="subjectList"
                  @setNewData="setNewData"
                  @submit="getstatsubjectapinfoData"
                  @loading="containerLoading = true"
                />
              </el-collapse-item>
            </div>
            <div :class="{ 'table-active': apInfo.apCalculateType === 1 }">
              <el-collapse-item name="2">
                <template slot="title">
                  <div class="operate" @click.stop="">
                    <el-radio
                      v-model="apInfo.apCalculateType"
                      :label="1"
                      @change="radioChange"
                      >使用分数分级</el-radio
                    >
                  </div>
                </template>
                <score-table
                  ref="scoreTable"
                  :source-data="{ tableData: tableData }"
                  :subject-list="subjectList"
                  @submit="getstatsubjectapinfoData"
                  @loading="containerLoading = true"
                />
              </el-collapse-item>
            </div>
          </el-collapse>
        </div>
      </div>
      <div class="right">
        <div class="ap-msg">
          <div class="top">
            <div class="top-title">
              <span class="number">参考人数：{{ apInfo.total }}</span>
              <span>缺考/零分人数：{{ apInfo.zeroTotal || 0 }}</span>
            </div>
            <el-checkbox
              v-if="![1, 2].includes(apInfo.apSource)"
              v-model="apInfo.apExcludeZero"
              :false-label="0"
              :true-label="1"
              :disabled="containerLoading"
              @change="apExcludeZeroChange"
              >排除缺考与零分考生</el-checkbox
            >
            <div class="ap-Num">赋分人数：{{ apInfo.apTotal }}</div>
          </div>
          <div class="msg-title">赋分规则</div>
          <div class="levelList">
            <div
              v-for="(item, index) in levelList"
              :key="index"
              class="level-item"
            >
              <div>{{ item.label }}</div>
              <div>{{ item.value }}</div>
            </div>
          </div>
        </div>
        <ratio-contrast
          v-if="showContrast('1') && apInfo.apSource === 0"
          :table-data="ratioContrastTableData"
          @updataContrast="setRatioData"
        />
        <score-contrast
          v-if="showContrast('2') && apInfo.apSource === 0"
          :table-data="scoreContrastTableData"
          @updataContrast="setScoreData"
        />
      </div>
    </div>
    <div class="apResult">
      <el-button type="primary" @click="showResult = !showResult">{{
        showResult ? "隐藏赋分结果" : "查看赋分结果"
      }}</el-button>
    </div>
    <Result
      v-if="showResult"
      ref="result"
      :key="chartKey"
      :subject-list="subjectList"
      @reset="reset"
    />
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :width="title === '选用历史比例' ? '30%' : '25%'"
      :before-close="handleClose"
    >
      <template v-if="title === '选用历史比例'">
        <history-ratio ref="history" />
      </template>
      <div v-if="title === '导入“赋分结果”向导'" class="uplaod-box">
        <upload-guide :key="uploadKey" @achivement="achivement" />
      </div>
      <span v-if="title === '选用历史比例'" slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="resetProportion">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import lineChart from "./conponents/charts.vue";
import { getstatapsubjectoption } from "@/core/api/academic/common";
import {
  getstatsubjectapinfo,
  getdefaultapproportion,
  setapexcludezero,
} from "@/core/api/academic/newExam";
import { setapsource } from "@/core/api/academic/statistics";
import { mapState } from "vuex";
import { levelList } from "./conponents/enum";
import ratioContrast from "./conponents/ratioContrast.vue";
import Result from "./conponents/result.vue";
import historyRatio from "./conponents/historyRatio.vue";
import ratioTable from "./conponents/ratioTable.vue";
import scoreTable from "./conponents/scoreTable.vue";
import scoreContrast from "./conponents/scoreContrast.vue";
import uploadFile from "./conponents/uploadFile.vue";
import originCreate from "./conponents/originCreate.vue";
import uploadGuide from "./conponents/uploadGuide.vue";
export default {
  components: {
    lineChart,
    ratioContrast,
    Result,
    historyRatio,
    ratioTable,
    scoreTable,
    scoreContrast,
    uploadFile,
    uploadGuide,
    originCreate,
  },
  inject: ["getEditGrade", "getExcloud"],
  data() {
    return {
      showRatioInput: false,
      dialogVisible: false,
      title: "",
      subjectList: [],
      activeNames: ["1", "2"],
      levelList: [...levelList],
      exclude: true, // 排除缺考与零分考生
      apInfo: { apSource: 0, apExcludeZero: 0, settings: [] },
      tableData: [],
      ratioContrastTableData: [],
      scoreContrastTableData: [],
      chartSeries: [],
      chartKey: Math.random(),
      showResult: true,
      apExcludeZero: null,
      excluoudType: null,
      hasUpload: false,
      uploadKey: null,
      isReset: false,
      loading: false,
      containerLoading: false,
      apSourceList: [
        {
          label: "选用系统设置赋分",
          value: 0,
        },
        {
          label: "选用表格导入赋分",
          value: 1,
        },
        {
          label: "选用原始分直接赋分",
          value: 2,
        },
      ],
      apFileName: null,
    };
  },
  computed: {
    ...mapState({
      subjectId: (state) => state.report.subjectId,
    }),
  },
  watch: {
    subjectId(val) {
      if (val) {
        this.upDataFileName();
        this.getstatsubjectapinfoData();
      }
    },
  },
  created() {
    this.getSubjectList();
  },
  methods: {
    achivement() {
      this.dialogVisible = false;
      this.getstatsubjectapinfoData();
      let subject = this.subjectList.find((item) => item.id === this.subjectId);
      this.upDataFileName();
      this.$message({
        type: "success",
        message: "科目“" + subject.name + "”比例分级已计算完成。",
        showClose: true,
      });
    },
    reUpload() {
      this.title = "导入“赋分结果”向导";
      this.dialogVisible = true;
      this.uploadKey = Math.random();
    },
    reset(val) {
      this.isReset = val;
    },
    upDate() {
      if (this.$refs.result) {
        this.$refs.result.getData();
      }
      // this.getSubjectList();
    },
    apSourceChange(val) {
      if (this.apInfo.apSource !== val && !this.containerLoading) {
        this.$confirm(
          "选用新的赋分方式将清除已有的赋分结果，要继续吗？",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(async () => {
            if ([1, 2].includes(val)) {
              await setapexcludezero({
                id: this.$route.query.id,
                apExcludeZero: 0,
              });
            }
            this.containerLoading = true;
            this.$parent.initExcloudZero();
            await setapsource({
              id: this.$route.query.id,
              apSource: val,
            });
            this.containerLoading = false;
            this.apInfo = { apSource: val, apExcludeZero: 0, settings: [] };
            this.chartSeries = [];
            this.getstatsubjectapinfoData();
            this.$refs.result.inputChange();
            this.$message({
              type: "success",
              message: "已切换!",
            });
          })
          .catch(() => {
            this.loading = false;
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      }
    },
    resetProportion() {
      if (this.subjectId) {
        let newProportion = this.$refs.history.values;
        this.tableData = this.tableData.map((item, index) => ({
          ...item,
          apProportion: newProportion[index],
        }));
        this.dialogVisible = false;
      } else {
        this.$message({
          showClose: true,
          message: "当前统计没有可赋分的选考科目",
          type: "warning",
        });
      }
    },
    setNewData(data) {
      this.tableData = data;
      this.$nextTick(() => {
        this.initChartsData();
        this.ratioContrastTableData = JSON.parse(JSON.stringify(data));
        this.scoreContrastTableData = JSON.parse(JSON.stringify(data));
      });
    },
    setRatioData() {
      if (!this.$refs.ratioTable.disabled) {
        this.ratioContrastTableData = JSON.parse(
          JSON.stringify(this.tableData)
        );
      }
    },
    async apExcludeZeroChange() {
      await setapexcludezero({
        id: this.$route.query.id,
        apExcludeZero: this.apInfo.apExcludeZero,
      });
      let data = this.apExcludeZero === this.apInfo.apExcludeZero;
      this.getstatsubjectapinfoData();
      if (!data) {
        this.getExcloud("change");
      } else {
        this.getExcloud("origin");
      }
    },
    setScoreData() {
      if (!this.$refs.scoreTable.disabled) {
        this.scoreContrastTableData = JSON.parse(
          JSON.stringify(this.tableData)
        );
      }
    },
    resetDefault() {
      if (this.subjectId) {
        this.$confirm("点击确认将恢复默认赋分比例, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            const res = await getdefaultapproportion({
              id: this.$route.query.id,
              subjectId: this.subjectId,
            });

            this.tableData.forEach((item, index) => {
              this.$set(item, "apProportion", res.data.data[index]);
            });
            this.$refs.ratioTable.initForm();
            this.$message({
              type: "success",
              message: "已恢复默认比例",
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      } else {
        this.$message({
          showClose: true,
          message: "当前统计没有可赋分的选考科目",
          type: "warning",
        });
      }
    },
    radioChange() {
      let radioStr = this.apInfo.apCalculateType;
      if (!this.activeNames.includes(radioStr)) {
        this.activeNames.push(this.radioStr);
      }
      this.initChartsData();
    },
    async getstatsubjectapinfoData() {
      this.loading = true;
      const res = await getstatsubjectapinfo({
        id: this.$route.query.id,
        subjectId: this.subjectId,
      }).catch(() => {
        this.loading = false;
      });
      this.loading = this.containerLoading = false;
      this.apInfo = res.data.data;
      this.tableData = res.data.data.settings;
      if (this.$refs.ratioTable) this.$refs.ratioTable.initForm();
      if (this.$refs.scoreTable) this.$refs.scoreTable.initForm();
      this.apExcludeZero = res.data.data.apExcludeZero;
      if (this.apInfo.apSource === 0) {
        this.$refs.ratioTable.apCalculateWithScore =
          this.apInfo.apCalculateWithScore;
      }
      this.getEditGrade(this.apInfo.hasChangeScore);
      this.initChartsData();
    },
    initChartsData() {
      // 初始化echarts数据
      if (this.apInfo.apCalculateType == 0) {
        this.chartSeries = [
          {
            name: "实际赋分比例",
            type: "line",
            symbol: "circle", //拐点的形状
            data: this.tableData.map((item) => item.realProportion),
            label: {
              show: true,
              position: "top",
              color: "inherit",
            },
          },
        ];
        if (this.apInfo.apSource !== 2 && this.apInfo.apSource !== 1) {
          this.chartSeries.push({
            name: "录入比例",
            type: "line",
            symbol: "circle", //拐点的形状
            data: this.tableData.map((item) => item.apProportion),
            label: {
              show: true,
              position: "top",
              color: "inherit",
            },
          });
        }
        this.chartKey = Math.random();
      } else if (this.apInfo.apCalculateType == 1) {
        this.chartSeries = [
          {
            name: "实际赋分比例",
            type: "line",
            symbol: "circle", //拐点的形状
            data: this.tableData.map((item) => item.realScoreProportion),
            label: {
              show: true,
              position: "top",
              color: "inherit",
            },
          },
        ];
        this.chartKey = Math.random();
      }
    },
    chooseHistory() {
      this.title = "选用历史比例";
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },

    scoreChange() {
      this.scoreDisabled = !this.scoreGradeData.every(
        (item) => item.score || item.score === 0
      );
    },
    upDataFileName() {
      let storage = window.localStorage.getItem("export-ap");
      let obj = JSON.parse(storage);
      if (obj) {
        this.apFileName =
          obj[`${this.$route.query.id}-${this.subjectId}`] || "";
      }
    },
    async getSubjectList() {
      const res = await getstatapsubjectoption({
        id: this.$route.query.id,
      });
      this.subjectList = res.data.data;
      if (res.data.data && res.data.data.length) {
        this.$store.commit("report/SET_SUBJECT_ID", res.data.data[0].id);
        this.$store.commit("report/SET_SUBJECT_LIST", res.data.data);
        this.upDataFileName();
        this.getstatsubjectapinfoData();
      }
    },
    showContrast(value) {
      const val = this.activeNames.includes(value);
      return val;
    },
  },
};
</script>
<style lang="scss" scoped>
.ap {
  border-radius: 4px;
  // border: 1px solid #ebecee;
  .ap-type {
    height: 36px;
    background: #f5f7fa;
    border-radius: 4px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 18px;
    margin-bottom: 18px;
    ::v-deep .el-radio-group {
      .el-radio {
        margin-right: 24px;
        margin-bottom: 0;
      }
    }
  }
  .content {
    display: flex;
    align-items: flex-start;
    .left {
      margin-right: 68px;
      width: calc(100% - 418px);
      .table-contain {
        border-radius: 4px;
        border: 1px solid #ebecee;
        .ratio-grade {
          box-sizing: border-box;
          padding: 18px;
          // border-top: 1px solid #ebecee;
        }
        .score-grade {
          box-sizing: border-box;
          padding: 18px;
        }
      }
    }
    .right {
      width: 350px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      border: 1px solid #ebecee;
      .ap-msg {
        width: 350px;

        border-radius: 4px;
        box-sizing: border-box;
        padding: 18px;
        .top {
          width: 100%;
          background-color: #f3f8ff;
          box-sizing: border-box;
          padding: 18px;
          .top-title {
            font-weight: 550;
            margin-bottom: 18px;
            .number {
              margin-right: 23px;
            }
          }
          .ap-Num {
            width: 100%;
            margin-top: 8px;
            background: #ffffff;
            border-radius: 4px;
            height: 45px;
            text-align: center;
            font-size: 16px;
            font-weight: 550;
            line-height: 45px;
          }
        }
        .msg-title {
          font-weight: 400;
          margin: 18px 0;
        }
        .levelList {
          .level-item {
            display: flex;
            background: #f5f7fa;
            align-items: center;
            border-radius: 4px;
            height: 36px;
            justify-content: space-between;
            box-sizing: border-box;
            padding: 0 18px;
            margin-bottom: 8px;
          }
          &5 {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .apResult {
    text-align: center;
    margin-top: 18px;
  }
  .table-input {
    ::v-deep .el-input__inner {
      text-align: center;
    }
  }
  .operate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    padding: 0 18px;
    ::v-deep .el-radio {
      margin-bottom: 0;
    }
  }
}
::v-deep .el-collapse {
  border-top: none;
  .el-collapse-item {
    border: none;
    .el-collapse-item__content {
      padding-bottom: 0;
    }
    .el-collapse-item__wrap {
      border-bottom: none;
    }
    .el-collapse-item__header {
      border-bottom: none;
      border-top: 1px solid #ebecee;
    }
  }
}
.apSource-group {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .apSource {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    user-select: none;
    cursor: pointer;
    color: #606266;
    font-weight: 500;
    font-style: 14px;
    .check {
      margin-right: 8px;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      overflow: hidden;
      border: 1px solid #dcdfe6;
      position: relative;
    }
    .checked {
      transform: rotate(-45deg);
      white-space: nowrap;
      cursor: pointer;
      outline: 0;
      display: inline-block;
      line-height: 1;
      position: relative;
      vertical-align: middle;
      border-color: #2474ed;
      background: #2474ed;
      &::after {
        content: "";
        width: 70%;
        height: 40%;
        border: 2px solid white;
        border-top: transparent;
        border-right: transparent;
        text-align: center;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        vertical-align: middle;
        transform: translate(-40%, -60%);
        -ms-transform: translate(-40%, -60%);
        -moz-transform: translate(-40%, -60%);
        -webkit-transform: translate(-40%, -60%);
        border-radius: 0px;
        background: none;
      }
    }
  }
  .apSource + .apSource {
    margin-left: 18px;
  }
}
.uplaod-box {
  box-sizing: border-box;
  padding: 18px;
}
.reUpload {
  margin: 18px;
}
.table-active {
  border: 1px solid #2474ed;
}
::v-deep .el-collapse-item {
  &:last-child {
    margin-bottom: 0 !important;
  }
}
.ban {
  cursor: not-allowed;
}
</style>
