<template>
  <div class="result">
    <div class="title">选择科目</div>
    <subjects :subject-list="subjectList" />
    <div class="result-filter">
      <div class="filter-left">
        <div v-if="$route.query.examType == 1" class="filter-item">
          学校
          <el-select
            v-model="schoolId"
            style="marign-left: 10px"
            placeholder="全部行政班"
            @change="schoolChange"
          >
            <el-option
              v-for="item in schoolList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="filter-item">
          行政班
          <el-select
            v-model="adminClass"
            style="marign-left: 10px"
            clearable
            placeholder="全部行政班"
            @change="classChange"
          >
            <el-option
              v-for="item in adminClassData"
              :key="item.name"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </div>
        <div class="filter-item">
          教学班
          <el-select
            v-model="teachClass"
            style="marign-left: 10px"
            placeholder="全部教学班"
            clearable
            @change="classChange"
          >
            <el-option
              v-for="item in teachClassData"
              :key="item.name"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </div>
        <div class="filter-item">
          <el-input
            v-model="input"
            style="width: 220px"
            placeholder="根据姓名或考号搜索"
            clearable
            @input="inputChange"
          ></el-input>
        </div>
      </div>
      <div class="filter-right">
        <el-dropdown @command="handleCommand">
          <el-button>
            导出为Excel<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="current">当前榜单</el-dropdown-item>
            <el-dropdown-item command="detail">详细榜单</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column prop="studentExamId" label="考号" align="center">
      </el-table-column>
      <el-table-column prop="name" label="姓名" align="center">
      </el-table-column>
      <el-table-column prop="classNum" label="行政班" align="center">
      </el-table-column>
      <el-table-column prop="classNumTeach" label="教学班" align="center">
      </el-table-column>
      <el-table-column prop="eleccomb" label="选考组合" align="center">
      </el-table-column>
      <el-table-column prop="scoreOri" label="原始分" align="center">
      </el-table-column>
      <el-table-column prop="score" label="赋分" align="center">
      </el-table-column>
      <el-table-column prop="apName" label="等级" align="center">
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        :current-page="page.current"
        :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
        :page-size="page.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="导出详细榜单"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div class="export">
        <div class="export-title">选择更多数据项</div>
        <div class="export-box">
          <div class="export-header">
            <el-checkbox
              v-model="exportForm.expApLevel"
              :true-label="1"
              :false-label="0"
              >表1：赋分分级表</el-checkbox
            >
          </div>
          <div class="export-content">
            <el-checkbox
              v-model="exportForm.expApLevelProportion"
              :true-label="1"
              :false-label="0"
              @change="firstCellChange"
              >比例分级表</el-checkbox
            >
            <el-checkbox
              v-model="exportForm.expApLevelScore"
              :true-label="1"
              :false-label="0"
              @change="firstCellChange"
              >分数分级表</el-checkbox
            >
          </div>
        </div>
        <div class="export-box">
          <div class="export-header">
            <el-checkbox
              v-model="exportForm.expApResult"
              :true-label="1"
              :false-label="0"
              >表2：赋分结果表</el-checkbox
            >
          </div>
          <div class="export-content">
            <div class="sub-title">考生信息</div>
            <div class="checkbox-box">
              <el-checkbox
                v-model="value"
                disabled
                :true-label="1"
                :false-label="0"
                >姓名</el-checkbox
              >
              <el-checkbox
                v-model="value"
                disabled
                :true-label="1"
                :false-label="0"
                >考号</el-checkbox
              >
            </div>
            <div class="sub-title">班级信息</div>
            <div class="checkbox-box">
              <el-checkbox
                v-model="exportForm.expApResultAdminClass"
                :true-label="1"
                :false-label="0"
                >行政班级</el-checkbox
              >
              <el-checkbox
                v-model="exportForm.expApResultTeachClass"
                :true-label="1"
                :false-label="0"
                >教学班级</el-checkbox
              >
            </div>
            <div class="sub-title">科目信息</div>
            <div class="checkbox-box">
              <el-checkbox
                v-model="value"
                :true-label="1"
                :false-label="0"
                disabled
                >选考组合</el-checkbox
              >
            </div>
            <div class="sub-title">分数与等级</div>
            <div class="checkbox-box">
              <el-checkbox
                v-model="exportForm.expApResultScoreOri"
                :true-label="1"
                :false-label="0"
                >原始分</el-checkbox
              >
              <el-checkbox
                v-model="value"
                disabled
                :true-label="1"
                :false-label="0"
                >赋分</el-checkbox
              >
              <el-checkbox
                v-model="exportForm.expApResultLevel"
                :true-label="1"
                :false-label="0"
                >等级</el-checkbox
              >
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="exportDetails"
          >导 出</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import subjects from "./subject.vue";
import {
  apscorepage,
  getstatteachclassoption,
  exportapscore,
  exportapscoredetails,
} from "@/core/api/academic/newExam";
import {
  getstatschooloption,
  getstatclassoption,
} from "@/core/api/academic/common";
import { mapState } from "vuex";
export default {
  components: {
    subjects,
  },
  props: {
    subjectList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      value: null,
      options: [],
      tableData: [],
      dialogVisible: false,
      input: "",
      page: {
        current: 1,
        size: 20,
      },
      schoolList: [],
      schoolId: 0,
      btnLoading: false,
      total: 0,
      adminClass: "", // 行政班,
      teachClass: "", // 教学班
      loading: false,
      adminClassData: [],
      teachClassData: [],
      apIndeterminate: false,
      resultIndeterminate: false,
      isReset: false,

      exportForm: {
        expApLevel: 1, // 是否勾选赋分分级表
        expApLevelProportion: 1, // 是否勾选比例分级表
        expApLevelScore: 1, // 是否勾选分数分级表
        expApResult: 1, //是否勾选赋分结果表
        expApResultAdminClass: 1, // 是否勾选行政班级
        expApResultLevel: 1, // 是否勾选等级
        expApResultScoreOri: 1, // 是否勾选原始分
        expApResultTeachClass: 1, // 是否勾选教学班级
      },
    };
  },
  computed: {
    ...mapState({
      subjectId: (state) => state.report.subjectId,
    }),
  },
  mounted() {
    if (this.$route.query.examType == 1) {
      this.getSchoolList();
    } else {
      this.getAdminClassData();
      this.getTeachClassData();
      this.getData();
    }
  },
  methods: {
    schoolChange() {
      this.adminClass = null;
      this.teachClass = null;
      this.getAdminClassData();
      this.getTeachClassData();
      this.getData();
    },
    async getSchoolList() {
      const res = await getstatschooloption({ id: this.$route.query.id });
      this.schoolList = [
        {
          name: "全部",
          id: 0,
        },
        ...res.data.data,
      ];
      if (this.schoolList.length) {
        this.schoolChange();
      }
    },
    firstCellChange() {
      const { expApLevelProportion, expApLevelScore } = this.exportForm;
      const result = [expApLevelProportion, expApLevelScore].every(
        (item) => item === 0
      );
      if (result) {
        this.exportForm.expApLevel = 0;
      }
    },
    async exportDetails() {
      this.btnLoading = true;
      try {
        const res = await exportapscoredetails({
          ...this.exportForm,
          ...this.page,
          subjectId: this.subjectId,
          statId: this.$route.query.id,
          viewSchoolId: this.schoolId,
        });
        const { name, url } = res.data.data;
        const { href } = this.$router.resolve({
          path: "/downFile",
          query: {
            url: url,
            name: name,
            type: 1,
          },
        });
        let timer = setTimeout(() => {
          window.open(href, "_blank");
          clearTimeout(timer);
        }, 0);
        this.btnLoading = false;
      } catch {
        this.btnLoading = false;
      }
    },
    inputChange() {
      this.page.current = 1;
      this.getData();
    },
    classChange() {
      this.page.current = 1;
      this.getData();
    },
    async getAdminClassData() {
      if (this.subjectId) {
        const res = await getstatclassoption({
          id: this.$route.query.id,
          subjectId: 0, // 传科目id 后端会去查教学班
          viewSchoolId: this.schoolId,
          isNeedTeach: 0,
        });
        this.adminClassData = res.data.data;
      }
    },
    async getTeachClassData() {
      if (this.subjectId) {
        const res = await getstatteachclassoption({
          id: this.$route.query.id,
          subjectId: this.subjectId,
          viewSchoolId: this.schoolId,
        });
        this.teachClassData = res.data.data;
      }
    },
    async getData(val) {
      this.loading = true;
      try {
        const res = await apscorepage({
          adminClass: this.adminClass,
          teachClass: this.teachClass,
          subjectId: this.subjectId,
          viewSchoolId: this.schoolId,
          input: this.input,
          statId: this.$route.query.id,
          ...this.page,
        });

        this.tableData = res.data.data.records;
        this.total = res.data.data.total;
        let result = res.data.data.records.every((item) => item.score === 0);
        this.isReset = result;
        if (!val) {
          this.$emit("reset", this.isReset);
        }
      } catch {
        this.loading = false;
      }
    },
    handleCommand(command) {
      if (command === "current") {
        exportapscore({
          statId: this.$route.query.id,
          viewSchoolId: this.schoolId,
          subjectId: this.subjectId,
        }).then((res) => {
          const { name, url } = res.data.data;
          const { href } = this.$router.resolve({
            path: "/downFile",
            query: {
              url: url,
              name: name,
              type: 1,
            },
          });
          let timer = setTimeout(() => {
            window.open(href, "_blank");
            clearTimeout(timer);
          }, 0);
        });
      } else if (command === "detail") {
        this.dialogVisible = true;
      }
    },
    handleSizeChange(val) {
      this.page.size = val;
      this.getData("handleSizeChange");
    },
    handleCurrentChange(val) {
      this.page.current = val;
      this.getData("handleCurrentChange");
    },
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.result {
  color: #0a1119;
  font-size: 14px;
  width: 100%;
  margin-top: 18px;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 18px;
  border: 1px solid #ebecee;

  .title {
    font-weight: 550;
    margin-bottom: 18px;
  }

  .result-filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;

    .filter-left {
      display: flex;
      align-items: center;

      .filter-item {
        margin-right: 18px;
      }
    }
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .export {
    font-size: 14px;
    color: #0a1119;

    &-title {
      margin-bottom: 18px;
    }

    .export-box {
      border: 1px solid #ebecee;
      border-radius: 4px;
      margin-bottom: 42px;

      .export-header {
        box-sizing: border-box;
        background: #f5f7fa;
        padding: 12px 18px;

        .el-checkbox {
          margin-bottom: 0;
        }
      }

      .export-content {
        box-sizing: border-box;
        padding: 14px 35px;
        background-color: #fff;

        .el-checkbox {
          margin-bottom: 0;
        }

        .sub-title {
          margin-bottom: 12px;
        }

        .checkbox-box {
          display: flex;
          align-items: center;
          margin-bottom: 24px;
        }
      }
    }
  }
}
</style>
