<template>
  <div class="chart">
    <div class="title">选择科目</div>
    <subjects :subject-list="subjectList" />
    <div class="sub-title">“实际赋分比例”与“录入比例”对比图</div>
    <div ref="lineCharts" class="line-charts" style="width: 100%"></div>
  </div>
</template>
<script>
import subjects from "./subject.vue";
import * as echarts from "echarts";
export default {
  components: {
    subjects,
  },
  props: {
    subjectList: {
      type: Array,
      default: () => [],
    },
    series: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {};
  },

  mounted() {
    this.initCharts();
  },
  methods: {
    initCharts() {
      let options = {
        tooltip: {
          trigger: "axis",
        },
        grid: {
          left: "4%",
          right: "4%",
          bottom: "8%",
          containLabel: false,
        },
        legend: {},
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["A", "B", "C", "D", "E"],
        },
        yAxis: {
          type: "value",
        },
        color: ["#2474ED", "#33C088"],
        series: [...this.series],
      };
      const chart = echarts.init(this.$refs.lineCharts);
      options && chart.setOption(options, true);
      // let that = this;
      // setTimeout(() => {
      //   window.onresize = function () {
      //     that.chartObj.resize();
      //   };
      // }, 200);
      window.addEventListener("resize", function () {
        chart.resize();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.chart {
  box-sizing: border-box;
  padding: 18px;
  color: #0a1119;
  width: 100%;
  font-size: 14px;
  // border-bottom: 1px solid #ebecee;
  .title {
    font-weight: 550;
    letter-spacing: 1px;
    width: 100%;
    margin-bottom: 18px;
  }
  .sub-title {
    color: #3e4551;
    width: 100%;
    margin-bottom: 20px;
  }
  .line-charts {
    width: 100%;
    height: 300px;
  }
}
</style>
