<template>
  <div class="uploadFile">
    <img src="@/assets/imgs/academic/download.png" alt="" />
    <div class="text">
      <div>使用表格数据作为赋分结果</div>
      <div>若要修改赋分，可重新导入数据</div>
    </div>
    <el-button type="primary" @click="open">导入Excel表格</el-button>
    <el-dialog
      :title="'导入“赋分结果”向导'"
      :visible.sync="dialogVisible"
      align="center"
      width="35%"
      :before-close="handleClose"
    >
      <div class="uplaod-box">
        <upload-guide :key="dialogKey" @achivement="achivement" />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import uploadGuide from "./uploadGuide.vue";
import { mapState } from "vuex";
export default {
  components: {
    uploadGuide,
  },
  data() {
    return {
      dialogVisible: false,
      dialogKey: null,
    };
  },
  computed: {
    ...mapState({
      subjectId: (state) => state.report.subjectId,
    }),
  },
  methods: {
    open() {
      this.dialogKey = Math.random();
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    achivement() {
      this.$emit("submit");
      this.dialogVisible = false;
      let subject = this.$parent.subjectList.find(
        (item) => item.id === this.subjectId
      );
      this.$message({
        type: "success",
        message: "科目“" + subject.name + "”比例分级已计算完成。",
        showClose: true,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.uploadFile {
  width: 100%;
  height: 445px;
  margin-bottom: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  img {
    margin-bottom: 32px;
  }
  .text {
    color: #3e4551;
    font-size: 14px;
    text-align: center;
    margin-bottom: 18px;
  }
}
.uplaod-box {
  box-sizing: border-box;
  padding: 18px;
}
</style>
