<template>
  <div class="score-grade">
    <el-form ref="form" :model="sourceData">
      <el-table
        :data="sourceData.tableData"
        border
        style="width: 100%; margin-bottom: 18px"
      >
        <el-table-column prop="name" label="等级" align="center">
        </el-table-column>
        <el-table-column label="设定分数" align="center">
          <template slot-scope="{ row, $index }">
            <div class="table-block">
              <span>{{ row.realScoreIntervalMax }}~</span>
              <el-form-item
                class="table-form-item"
                :prop="'tableData.' + $index + '.realScoreIntervalMin'"
                :rules="{
                  validator: (rule, value, callback) =>
                    validScoreMin(rule, value, callback, row, $index),
                  trigger: 'blur',
                }"
              >
                <el-input
                  v-model.number="row.realScoreIntervalMin"
                  class="table-input"
                  style="width: 96px"
                ></el-input>
              </el-form-item>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="realScoreTotal" label="人数" align="center">
        </el-table-column>
        <el-table-column
          prop="realScoreProportion"
          label="实际比例"
          align="center"
        >
          <template slot-scope="{ row }">
            {{ row.realScoreProportion }}%
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <div class="operate">
      <div></div>
      <el-dropdown :disabled="disabled" @command="handleCommand">
        <el-button :loading="loading">
          {{ text }}<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="1">应用</el-dropdown-item>
          <el-dropdown-item command="2">应用更多科目</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-dialog
      title="应用至更多科目"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="handleClose"
    >
      <div class="title">对以下科目应用相同数值进行赋分</div>
      <el-checkbox-group v-model="checkList">
        <el-checkbox
          v-for="(item, index) in subjectList"
          :key="index"
          :disabled="subjectId == item.id"
          :label="item.id"
          >{{ item.name }}</el-checkbox
        >
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="loading" @click="submit"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { setstatsubjectaprealintervalmin } from "@/core/api/academic/newExam";
export default {
  props: {
    subjectList: {
      type: Array,
      default: () => [],
    },
    sourceData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      disabled: false,
      tableData: [],
      dialogVisible: false,
      checkList: [],
      loading: false,
      text: "应用",
    };
  },
  computed: {
    ...mapState({
      subjectId: (state) => state.report.subjectId,
    }),
  },
  methods: {
    initForm() {
      this.$refs.form.clearValidate();
    },
    validScoreMin(rule, value, callback, row, index) {
      let reg = /^\d+$/;
      if (!reg.test(value)) {
        callback(new Error(" "));
        // Message({
        //   type: "error",
        //   message: "设定分数只能为0或正整数",
        //   showClose: true,
        // });
      } else {
        if (value > row.realScoreIntervalMax) {
          callback(new Error(" "));
          // Message({
          //   type: "error",
          //   message: "有效分不能大于上一段有效分！",
          //   showClose: true,
          // });
        } else {
          if (index < 4) {
            if (
              value < this.sourceData.tableData[index + 1].realScoreIntervalMin
            ) {
              this.resetScore(value, index);
            } else {
              if (
                value - 1 <
                this.sourceData.tableData[index + 1].realScoreIntervalMin
              ) {
                this.resetScore(value, index);
              } else {
                if (value >= 0) {
                  this.$set(
                    this.sourceData.tableData[index + 1],
                    "realScoreIntervalMax",
                    value - 1
                  );
                } else {
                  this.$set(
                    this.sourceData.tableData[index + 1],
                    "realScoreIntervalMax",
                    0
                  );
                }
              }
            }
            callback();
          } else {
            callback();
          }
        }
      }
    },
    resetScore(value, $index) {
      let i = 0;
      this.sourceData.tableData.forEach((item, index) => {
        if ($index < index) {
          i++;
          this.$set(item, "realScoreIntervalMax", value - i);
          this.$set(item, "realScoreIntervalMin", value - (i + 1));
        }
      });
    },
    handleClose() {
      if (!this.laoding) {
        this.dialogVisible = false;
      }
    },
    async submit() {
      this.loading = true;
      let minArr = this.sourceData.tableData.map(
        (item) => item.realScoreIntervalMin
      );
      try {
        await setstatsubjectaprealintervalmin({
          id: this.$route.query.id,
          subjectIdMore: this.checkList.join(","),
          subjectId: this.subjectId,
          values: minArr.splice(0, 4),
        });
        this.loading = false;
        this.dialogVisible = false;
        this.$message({
          type: "success",
          showClose: true,
          message: "提交成功",
        });
      } catch {
        this.loading = false;
      }
    },
    handleCommand(e) {
      if (this.subjectId) {
        this.$refs.form.validate(async (valid) => {
          if (valid) {
            this.$refs.form.clearValidate();
            if (e === "1") {
              let minArr = this.sourceData.tableData.map(
                (item) => item.realScoreIntervalMin
              );
              this.loading = true;
              this.$emit("loading");
              this.text = "正在应用分数分级";
              await setstatsubjectaprealintervalmin({
                id: this.$route.query.id,
                subjectId: this.subjectId,
                values: minArr.splice(0, 4),
              });
              this.text = "应用";
              this.loading = false;

              this.$emit("submit");
            } else if (e === "2") {
              this.dialogVisible = true;
              this.$nextTick(() => {
                this.checkList = [this.subjectId];
              });
            }
          } else {
            let reg = /^\d+$/;
            const result = this.sourceData.tableData.some(
              (item) => !reg.test(item.realScoreIntervalMin)
            );
            if (result) {
              this.$message({
                type: "error",
                message: "设定分数只能为0或正整数",
                showClose: true,
              });
            } else {
              const inre = this.sourceData.tableData.some(
                (item) => item.realScoreIntervalMin > item.realScoreIntervalMax
              );
              if (inre) {
                this.$message({
                  type: "error",
                  message: "有效分不能大于上一段有效分！",
                  showClose: true,
                });
              } else {
                const err = this.sourceData.tableData.some(
                  (item) => item.realScoreIntervalMin < 0
                );
                if (err) {
                  this.$message({
                    type: "error",
                    message: "有效分不能为负数",
                    showClose: true,
                  });
                }
              }
            }
          }
        });
      } else {
        this.$message({
          showClose: true,
          message: "当前统计没有可赋分的选考科目",
          type: "warning",
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.operate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  // padding: 0 18px;
  ::v-deep .el-radio {
    margin-bottom: 0;
  }
}
.title {
  color: #0a1119;
  font-size: 14px;
  margin-bottom: 18px;
}
.table-form-item {
  margin-bottom: 0;
}
.table-block {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
